<template>
  <teleport to="body">
    <div
      v-show="visibility"
      ref="overlayRef"
      @click="close(true)"
      class="fixed top-0 z-9999 left-0 h-full w-full opacity-40 bg-black backdrop-blur-sm"
    ></div>
    <div
      ref="bottomSheetRef"
      class="bottom-0 z-9999 w-full md:px-4 right-0 py-4 h-fit md:py-7 translate-y-full transform overflow-hidden bg-white text-neutral-black-1 transition duration-200 ease-in-out md:w-[42rem] fixed left-0 md:m-auto rounded-t-2xl md:rounded-2xl"
      :class="[
        { 'translate-y-0 md:top-0  transform-none': visibility },
        ' z-50',
      ]"
    >
      <div class="w-full">
        <div class="px-4 md:px-2 w-full">
          <div
            class="flex font-bodoni flex-col gap-6 items-center justify-center"
          >
            <button
              v-show="isMobileOrTablet"
              ref="dragIconRef"
              aria-label="Cerrar"
              class="w-14 h-1.5 bg-gray-300 rounded"
              @click="close(false)"
            ></button>
            <slot name="title"></slot>
          </div>
          <slot name="subtitle"></slot>
        </div>
        <div class="px-4 mt-6 w-full">
          <slot name="content"></slot>
        </div>
        <div v-if="!!$slots.footer" class="bg-white w-full mt-6 px-4 py-2">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script setup>
const props = defineProps({
  visibility: {
    type: Boolean,
    default: false,
  },
  closeOnClickOutside: {
    type: Boolean,
    default: true,
  },
  zIndex: {
    type: Number,
    default: 50,
  },
})

const { cart: cartValue } = useCart()

const { isMobileOrTablet } = useDevice()
const isDragging = ref(false)
const startY = ref(0)
const startHeight = ref(0)

const bottomSheetRef = ref(null)
const overlayRef = ref(null)
const dragIconRef = ref(null)

const dragStart = (e) => {
  if (!bottomSheetRef.value) return
  isDragging.value = true
  startY.value = e.pageY || e.touches?.[0].pageY
  startHeight.value = bottomSheetRef.value.clientHeight
}

const dragging = (e) => {
  if (!isDragging.value) return
  if (!bottomSheetRef.value) return
  const currentY = e.pageY || e.touches?.[0].pageY
  const diff = currentY - startY.value
  const newHeight = startHeight.value - diff
  if (newHeight > startHeight.value) return

  // decrease overlay opacity from 0.4 to 0 on height 0
  if (overlayRef.value)
    overlayRef.value.style.opacity = `${(newHeight * 0.4) / startHeight.value}`

  bottomSheetRef.value.style.height = `${newHeight}px`
}

const dragStop = () => {
  if (!bottomSheetRef.value) return
  isDragging.value = false
  const sheetHeight = bottomSheetRef.value.clientHeight
  sheetHeight < 130
    ? emit('close')
    : (bottomSheetRef.value.style.height = 'fit-content')
}

onMounted(() => {
  watchEffect(() => {
    if (props.visibility) {
      document.body.style.overflow = 'hidden'

      if (bottomSheetRef.value)
        bottomSheetRef.value.style.height = 'fit-content'
      addListeners()
    } else {
      if (cartValue.value) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'auto'
      }
      removeListeners()
    }
  })
})

const addListeners = () => {
  dragIconRef.value.addEventListener('mousedown', dragStart)
  dragIconRef.value.addEventListener('touchstart', dragStart, { passive: true })
  window.addEventListener('mousemove', dragging)
  window.addEventListener('touchmove', dragging, { passive: true })
  window.addEventListener('mouseup', dragStop)
  window.addEventListener('touchend', dragStop, { passive: true })
}

const removeListeners = () => {
  dragIconRef.value.removeEventListener('mousedown', dragStart)
  dragIconRef.value.removeEventListener('touchstart', dragStart, {
    passive: true,
  })
  window.removeEventListener('mousemove', dragging)
  window.removeEventListener('touchmove', dragging, { passive: true })
  window.removeEventListener('mouseup', dragStop)
  window.removeEventListener('touchend', dragStop, { passive: true })
}

const emit = defineEmits(['close'])

const close = (outside) => {
  if (!outside || (outside && props.closeOnClickOutside)) emit('close')
}
</script>
